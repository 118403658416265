<template>
  <v-container class="container--fluid grid-list-md">
      <h2 class="mb-2">{{$t('route.catalog.store')}}</h2>
    <div class="d-md-flex justify-space-between align-end mb-3">
      <v-row>
        <v-col xs="12" sm="6" md="3" lg="3" lx="3">
          <v-autocomplete
              label="Категория магазина"
              clearable
              dense
              solo
              hide-details
              :items="categories"
              v-model="currentCat"
              item-text="name"
              item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col xs="12" sm="6" md="3" lg="3" lx="3">
          <v-autocomplete
              label="Вендор"
              clearable
              dense
              solo
              hide-details
              :items="vendors"
              v-model="currentVendor"
              item-text="title"
              item-value="title"
          ></v-autocomplete>
        </v-col>
        <v-col xs="12" sm="6" md="3" lg="3" lx="3">
          <v-autocomplete
              label="Категория Rozetka"
              clearable
              dense
              solo
              hide-details
              :items="categoriesRozList"
              v-model="currentRozCat"
              item-text="name"
              item-value="id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-btn-toggle
          mandatory
          class="mr-4"
      >
        <v-btn small outlined color="blue">
          Все
        </v-btn>
        <v-btn small outlined color="green">
          В XML
        </v-btn>
        <v-btn small outlined color="red">
          Не в XML
        </v-btn>
      </v-btn-toggle>
      <v-btn
          outlined
          small
          @click="changeViewExpand()"
      >
        <v-icon small left>mdi-eye-outline</v-icon>
        <span v-if="activeRowsEx.length === productsList.length">{{ $t('btns.hide_details') }}</span>
        <span v-else>{{ $t('btns.show_details') }}</span>
      </v-btn>
    </div>
    <div
        class="wrap-progress"
    >
      <v-progress-linear
          indeterminate
          height="2"
          light
          absolute
          :active="getLoading"
      ></v-progress-linear>
    </div>
    <custom-table
        :headers="headers"
        :items="products"
        selectable="multiple"
        :expand-rows="expandRows"
        @toggleExpand="toggleEx"
        :active-expand-rows="activeRowsEx"
        height="100%"
        @send="open"
        @eSort="eSort"
        :defaultIcon=null
        :showPagin=true
        :typePaginateServer=true
        :pramsPag="pramsPag"
        @paginate="pagination"
        :page="current_page"

    />
<!--    <div class="text-center">-->
<!--      <v-pagination-->
<!--          class="mt-2"-->
<!--          v-model="current_page"-->
<!--          :length="all_page"-->
<!--          :total-visible="7"-->
<!--      ></v-pagination>-->
<!--    </div>-->
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'AllProducts',
  data() {
    return {
      pramsPag: {},
      productsList: [],
      categoriesList: [],
      categoriesRozList: [],
      activeRowsEx: [],
      current_page: null,
      currentCat: null,
      vendors: [],
      currentVendor: null,
      currentRozCat: null,
      headers: [
        {
          value: "breadCrumbs",
          width: 65,
          template: 'images',
        },
        {
          text: this.$t('fields.name2'),
          value: "name",
          width: 500,
          sortable: true,
          edit: false,
          search: true,
          template: 'name',
        },
        {
          text: this.$t('fields.category'),
          value: "categoryName",
          width: 230,
          sortable: true,
          edit: false,
          search: true,
        },
        {
          text: this.$t('fields.Product_Code_SKU'),
          value: "code",
          width: 130,
          sortable: true,
          edit: false,
          search: true,
        },
        {
          text: this.$t('fields.vendor'),
          value: "vendor",
          width: 200,
          sortable: true,
          edit: false,
          search: true,
        },
      ],
      expandRows: [
        {
          value: "description",
          template: "description",
          width: 400,
          title: this.$t('fields.description'),
        },
        {
          value: "name",
          template: "params",
          width: 200,
          title: this.$t('fields.parameters_seller'),
        },
        {
          value: "name",
          template: "images",
          width: 500,
          title: this.$t('fields.photo'),
        },
      ],
      params: {
        'expand': 'categories',
      },
    }
  },
  mounted() {
    if (this.$route.query.page) {
      this.params.page = +this.$route.query.page;
      this.current_page = +this.$route.query.page
      if (this.$route.query.count)
        this.params["per-page"] = +this.$route.query.count;
    }
    this.getApiProducts()
    this.getApiCategories()
    this.initVendor().then(function () {
      this.vendors = this.getVendor
    }.bind(this))
    this.getApiCategoriesRoz()
  },
  computed: {
    ...mapGetters(["getProducts", "getLoading", "getCats", "getVendor", "getRozetkaCats"]),
    products() {
      let arr = []
      for (let product of this.productsList) {
        let obj = {
          ...product.translations[0],
          ...product,
        }
        arr.push(obj)
      }
      return arr
    },
    categories() {
      let arr = []
      for (let category of this.categoriesList) {
        let obj = {
          ...category.translations[0],
          ...category,
        }
        arr.push(obj)
      }
      return arr
    },
  },
  watch: {
    // current_page() {
    //   this.params.page = this.current_page
    //   this.getApiProducts()
    // },
    currentCat() {
      this.params.page = 1
      this.params["category[id]"] = this.currentCat
      this.getApiProducts()
    },
    currentVendor() {
      this.params.page = 1
      this.params["filter[vendor][ilike]"] = this.currentVendor
      this.getApiProducts()
    },
    currentRozCat() {
      this.params.page = 1
      this.params["filter[cr_cid]"] = this.currentRozCat
      this.getApiProducts()
    }
  },
  methods: {
    ...mapActions(["initProducts", "initCat", "initVendor", "initRozetkaCats"]),
    open(e) {
      if (e.id)
        this.$router.push(`/product/${e.categories[0].id}/${e.id}`)
    },
    pagination(e){
      console.log(e)
      this.params = {
        ...this.params,
        ...e
      }
      this.getApiProducts()
    },
    getApiProducts() {
      console.log(this.params)
      this.initProducts(this.params).then(function (success) {
        let response = this.getProducts
        this.productsList = response.items
        this.$router.push({
          query: {
            page: response._meta.currentPage,
            count: response._meta.perPage
          },
        });
        // this.current_page = response._meta.currentPage
        this.pramsPag = {
          size: response._meta.perPage,
          totalElements: response._meta.totalCount,
          totalPages: response._meta.pageCount
        }
      }.bind(this))
    },
    getApiCategories() {
      let params = {
        'per-page': 25,
      }
      this.initCat(params).then(function (success) {
        let response = this.getCats
        this.categoriesList = response.items
      }.bind(this))
    },
    getApiCategoriesRoz() {
      let params = {
        'per-page': 9999,
      }
      this.initRozetkaCats(params).then(function (success) {
        let response = this.getRozetkaCats
        this.categoriesRozList = response.items
      }.bind(this))
    },
    eSort(sort) {
      if(sort.by === 'name'){
        sort.by = 'translations.name'
      }
      this.params.sort = sort.dir === "asc" ? sort.by : "-" + sort.by
      this.getApiProducts(this.params)
    },
    toggleEx(e) {
      this.activeRowsEx = e
    },
    changeViewExpand() {
      let arr = []
      if (this.activeRowsEx.length === this.products.length) {
        this.activeRowsEx = []
      } else if (this.activeRowsEx.length < this.products.length) {
        this.products.forEach(prod => arr.push(prod.id))
        this.activeRowsEx = arr
      }
    },
  }
};
</script>

<style>
.col {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.search-input {
  max-width: 250px !important;
}
</style>
